.project-image{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  padding: 4rem;
  box-sizing: border-box;
  border: 2px;
  padding-top: 8rem;
  padding-bottom: 8rem;
  display: flex;
  max-height: 40rem;
  margin: 0px;
  flex-flow: row-reverse;

}

.img-first {
  display: inherit;
  width: 100%;
  min-width: 60%;
  height: auto;
}

.img-first[src*="criotive"]{
  max-height: 60vh;
  width: auto;

}

#page-height {
  height: 100vh!important;
}

@media only screen and (max-width: 768px) {

  .project-image {
    width: 90%;
    margin: 2rem auto 2rem auto;
    max-height: -webkit-fill-available;
  }




}
