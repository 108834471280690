@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Raleway);
.project-image{
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 50%;
  padding: 4rem;
  box-sizing: border-box;
  border: 2px;
  padding-top: 8rem;
  padding-bottom: 8rem;
  display: flex;
  max-height: 40rem;
  margin: 0px;
  -webkit-flex-flow: row-reverse;
          flex-flow: row-reverse;

}

.img-first {
  display: inherit;
  width: 100%;
  min-width: 60%;
  height: auto;
}

.img-first[src*="criotive"]{
  max-height: 60vh;
  width: auto;

}

#page-height {
  height: 100vh!important;
}

@media only screen and (max-width: 768px) {

  .project-image {
    width: 90%;
    margin: 2rem auto 2rem auto;
    max-height: -webkit-fill-available;
  }




}

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.burger-container {
  position: relative;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.burger {
  width: 1.5rem;
  height: 0.5rem;
  position: relative;
  display: block;
  margin: -4px auto 0;
  top: 50%;
}
.bar {
  width: 100%;
  height: 0.125rem;
  display: block;
  position: relative;
  background: #000;
  -webkit-transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}
.bar.topBar {
   -webkit-transform: translateY(-2px) rotate(0deg);
           transform: translateY(-2px) rotate(0deg);
 }
.bar.btmBar {
   -webkit-transform: translateY(6px) rotate(0deg);
           transform: translateY(6px) rotate(0deg);
 }


.menu-opened {
   height: 100%;
   -webkit-transition: all 0.3s ease-in, background 0.5s ease-in;
   transition: all 0.3s ease-in, background 0.5s ease-in;
   -webkit-transition-delay: 0.25s;
           transition-delay: 0.25s;
}
.menu-opened .burger-container {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}


.menu-opened .bar {
  -webkit-transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  -webkit-transition-delay: 0.2s;
          transition-delay: 0.2s;
}
.menu-opened .bar.topBar {
   -webkit-transform: translateY(3px) translateX(0px) rotate(45deg);
           transform: translateY(3px) translateX(0px) rotate(45deg);
}
.menu-opened .bar.btmBar {
   -webkit-transform: translateY(1px) translateX(0px) rotate(-45deg);
           transform: translateY(1px) translateX(0px) rotate(-45deg);
}



