@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Raleway');

body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.burger-container {
  position: relative;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.burger {
  width: 1.5rem;
  height: 0.5rem;
  position: relative;
  display: block;
  margin: -4px auto 0;
  top: 50%;
}
.bar {
  width: 100%;
  height: 0.125rem;
  display: block;
  position: relative;
  background: #000;
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0s;
}
.bar.topBar {
   transform: translateY(-2px) rotate(0deg);
 }
.bar.btmBar {
   transform: translateY(6px) rotate(0deg);
 }


.menu-opened {
   height: 100%;
   transition: all 0.3s ease-in, background 0.5s ease-in;
   transition-delay: 0.25s;
}
.menu-opened .burger-container {
  transform: rotate(90deg);
}


.menu-opened .bar {
  transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  transition-delay: 0.2s;
}
.menu-opened .bar.topBar {
   transform: translateY(3px) translateX(0px) rotate(45deg);
}
.menu-opened .bar.btmBar {
   transform: translateY(1px) translateX(0px) rotate(-45deg);
}


